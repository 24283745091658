<template>
  <!-- <div class="container">
  </div> -->
  <introduction />
</template>

<script>

import introduction from './components/introduction.vue'

export default {
  name: 'App',
  components: {
      introduction
  }
}
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  /* display: flex; */
  /* align-items: center; */
  width: 300px;
  height: 100px;
  position: absolute;
  top:0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
}

</style>
