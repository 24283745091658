<template>
    <div>
        <h1 id="test">Coming soon</h1>
    </div>
</template>


<script>
export default {
    setup() {
        
    },
}
</script>

<style scoped>
    h1{
        color:#16c79a;
    }
</style>